<template>
  <div>
    <b-nav card-header pills class="mt-1 ml-1 mb-0">
      <b-nav-item
        :to="{ name: $route.matched[3].meta.routePending }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >PENDING
        <span v-if="counterPending > 0" class="ml-1">
          <feather-icon
            icon
            :badge="counterPending > 99 ? '99+' : counterPending"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.matched[3].meta.routeObserved }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >OBSERVED
        <span v-if="counterObserved > 0" class="ml-1">
          <feather-icon
            icon
            :badge="counterObserved > 99 ? '99+' : counterObserved"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.matched[3].meta.routeSent }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >SENT
        <span v-if="counterSent > 0" class="ml-1">
          <feather-icon
            icon
            :badge="counterSent > 99 ? '99+' : counterSent"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-top-info border-3 border-table-radius px-0">
      <ChargeBackTable />
    </b-card>
  </div>
</template>
<script>
import ChargeBackTable from "@/views/commons/components/charge-back_v2/view/components/ChargeBackTable.vue"
import { mapGetters, mapActions } from "vuex"

export default {
  components: {
    ChargeBackTable,
  },
  data() {
    return {
      counters: {
        pending: 0,
        observed: 0,
        sent: 0,
      },
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      counterPending: "ChargeBackStoreV2/G_PENDING_CHARGEBACK_COUNTER",
      counterObserved: "ChargeBackStoreV2/G_OBSERVED_CHARGEBACK_COUNTER",
      counterSent: "ChargeBackStoreV2/G_SENT_CHARGEBACK_COUNTER",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module
    },
  },
  async created() {
    this.A_CHARGEBACK_COUNTERS(this.moduleId)
  },
  methods: {
    ...mapActions({
      A_CHARGEBACK_COUNTERS: "ChargeBackStoreV2/A_CHARGEBACK_COUNTERS",
    }),
  },
}
</script>
