<template>
  <div>
    <b-nav card-header pills class="mt-1 ml-1 mb-0">
      <b-nav-item
        :to="{ name: $route.matched[2].meta.routeRemove }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >CLIENT REMOVE CHARGE BACK
        <span v-if="counterRemovecb > 0" class="ml-1">
          <feather-icon
            icon
            :badge="counterRemovecb > 99 ? '99+' : counterRemovecb"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.matched[2].meta.routePayment }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >PAYMENT CLIENT
        <span v-if="counterPaymentClient > 0" class="ml-1">
          <feather-icon
            icon
            :badge="counterPaymentClient > 99 ? '99+' : counterPaymentClient"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.matched[2].meta.routeDepartment }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >DEPARTMENT</b-nav-item
      >
      <b-nav-item
        :to="{ name: $route.matched[2].meta.routeDispute }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        :class="isTabDispute && 'activeCustom'"
        >DISPUTE
        <span v-if="counterDispute > 0" class="ml-1">
          <feather-icon
            icon
            :badge="counterDispute > 99 ? '99+' : counterDispute"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-top-info border-3 border-table-radius px-0">
      <DisputeChargeBack v-if="isTabDispute" />
      <ChargeBackTable v-else />
    </b-card>
  </div>
</template>
<script>
import ChargeBackTable from "@/views/commons/components/charge-back_v2/view/components/ChargeBackTable.vue";
import DisputeChargeBack from "@/views/management/views/charge-back/components/DisputeChargeBack.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ChargeBackTable,
    DisputeChargeBack,
  },
  data() {
    return {
      counters: {
        remove: 0,
        client: 0,
        department: 0,
      },
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      counterRemovecb: "ChargeBackStoreV2/G_REMOVE_CHARGEBACK_COUNTER",
      counterPaymentClient:
        "ChargeBackStoreV2/G_PAYMENT_CLIENT_CHARGEBACK_COUNTER",
      counterDispute: "ChargeBackStoreV2/G_DISPUTE_CHARGEBACK_COUNTER",
    }),
    isTabDispute() {
      return this.$route.name == this.$route.matched[3].meta.routePending
        ? true
        : this.$route.name == this.$route.matched[3].meta.routeObserved
        ? true
        : this.$route.name == this.$route.matched[3].meta.routeSent;
    },
  },
  created() {
    this.A_CHARGEBACK_COUNTERS(this.moduleId);
  },
  methods: {
    ...mapActions({
      A_CHARGEBACK_COUNTERS: "ChargeBackStoreV2/A_CHARGEBACK_COUNTERS",
    }),
  },
};
</script>
<style scoped>
.activeCustom a {
  background: #8f5fe8 !important;
  color: white !important;
}
</style>